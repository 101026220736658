<template>
  <div>
    <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="User Roles"/>
        <v-spacer></v-spacer>
        <v-btn 
          v-show="canDo && canDo.can.includes('add')"
          class="mx-2" depressed dark color="primary" @click="addItem()">
            <v-icon left dark>mdi-plus-circle</v-icon> Add
        </v-btn>
      </v-toolbar> 
    <v-divider></v-divider>
    <div class="content-wrap">
      <v-row>
         <v-col col="4" sm="4">
            <v-simple-table >
                <template v-slot:default>
                <tbody>
                    <tr v-for="item in filterList" :key="item.id" @click="editItem(item)">
                    <td>{{ item.name }}</td>
                    <td width="80px"  align="right">
                        <v-btn 
                          v-if="canDo && canDo.can.includes('delete')"
                          class="ma-1" outlined color="red" icon tile small @click="deleteItem(item)">
                          <v-icon small>mdi-close</v-icon>
                        </v-btn>
                    </td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
         </v-col>
         <v-col col="8" sm="8" class="right-panel pt-0">
            <div v-show="dialog">
              <v-card-text class="pt-0">
                <v-form v-model="form_valid" ref="form">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-text-field 
                           v-model="editedItem.name" 
                           :rules="[validate_rules.required]" 
                           label="Role Name *" dense>
                        </v-text-field>
                      </v-col>
                      <v-col cols="10" sm="10"
                        v-if="canDo && canDo.can.includes('update')">
                        <v-combobox
                          :items="availableRouteGroup"
                          label="Select a route group..."
                          item-value="id"
                          item-text="name"
                          v-model="selected_routegroup"
                          :return-object="true"
                          class="ma-0"
                          dense
                          clearable>
                        </v-combobox>
                        
                      </v-col>
                      <v-col cols="2" sm="2"
                        v-if="canDo && canDo.can.includes('update')">
                        <v-btn depressed dark color="primary" small @click="addRouteGroup()">
                          <v-icon left dark>mdi-plus-circle</v-icon> Add
                        </v-btn>
                      </v-col>
                      <v-col cols="12" sm="12">
                          <v-simple-table class="text-small permission-table">
                              <template v-slot:default>
                              <thead>
                                  <tr>
                                    <td>Name</td>
                                    <td>Permissions</td>
                                    <td></td>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(permissions, routegroup_id ) in editedItem.permission" :key="routegroup_id">
                                    <td width="250" style="padding-top: 5px;vertical-align:top">
                                      {{ RoutegroupsArray[routegroup_id].name }}
                                    </td>
                                    <td>
                                        <v-checkbox
                                          v-for="(can,index) in RoutegroupsArray[routegroup_id].can" :key="index"
                                          v-model="editedItem.permission[routegroup_id] "
                                          :label="can.permission_name"
                                          :value="can.id"
                                          hide-details
                                          class="ma-0"
                                        ></v-checkbox>
                                        <!-- <div >
                                          {{ editedItem.permission[item.routegroup_id] }}
                                          {{ RoutegroupsArray[item.routegroup_id].can }}
                                        </div> -->
                                    </td>
                                    <td width="80px"  align="right" style="padding-top: 5px;vertical-align:top">
                                        <!-- <v-btn class="ma-1" outlined color="primary" icon tile small @click="editItem(item)">
                                          <v-icon small>mdi-pencil</v-icon>
                                        </v-btn> -->
                                        <v-btn 
                                          v-if="canDo && canDo.can.includes('update')"
                                          class="ma-1" outlined color="red" icon tile x-small @click="deleteRouteGroup(routegroup_id)">
                                          <v-icon x-small>mdi-close</v-icon>
                                        </v-btn>
                                    </td>
                                  </tr>
                              </tbody>
                              </template>
                          </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn v-if="canDo && canDo.can.includes('update')" color="blue darken-1" text @click="save">Save</v-btn>
              </v-card-actions>
            </div>
         </v-col>
      </v-row>
        
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import _ from 'lodash';

export default {
  name: 'UserRoles',
  components: {
    pageHeading
  },
  data: () => ({
    dialog: false,
    options: {
        filter: {
          keyword: '',
        }
    },
    selected_routegroup: null,
    routelist: 0,
    editedIndex: 0,
    editedItem: {
      id: -1,
      name: '',
      permission: null
    },
    defaultItem: {
      id: -1,
      name: '',
      permission: { "1" : [1,2] }
    },
    form_valid : false,
    validate_rules : {
      required: v => !!v || 'This field is required',
    }
  }),
  computed: {
    ...mapGetters(['canDo','loadingDataTable','allUserRoles','allUserRoutegroups','RoutegroupsArray','allUserPermissions']),
    headers(){
      return [
        {text: "Name", value: 'routegroup_id', width: '200px',sortable: false, align:'left'},
        {text: "Permissions", value: 'permission_id', sortable: false, align:'left' },
        {text: "", value: 'actions' , width: '100px', sortable: false , align:'left'},
      ]
    },
    isEdit () {
      return this.editedIndex > -1
    },
    formTitle () {
        return this.editedIndex === -1 ? 'New User Role' : 'Edit User Role'
    },
    availableRouteGroup(){
        var existRoute = this.editedItem.permission ? Object.keys(this.editedItem.permission) : [];
        return _.filter(this.allUserRoutegroups, function(query){
          if(existRoute && existRoute.length > 0){
            var isAvailable = existRoute.find((element) => element == query.id);
            return isAvailable ? false : true;
          } else {
            return true;
          }
      });
    },
    filterList () { 
      var filter = this.options.filter;
      
      // Filter list
      var filterList = _.filter(this.allUserRoles, function(query){
        var keyword = filter.keyword ? query.name.includes(filter.keyword) : true;
        return keyword
      });

      // Sort list by id
      return filterList.sort(function(a, b) {
            var x = a['id']; 
            var y = b['id'];
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    },
    input_permissions() {
      return this.editedItem.permission;
    }
  },
  filters:{
    routegroup_name(value, obj){
        return  obj[0] ? obj[0].name : '';
    }
  },
  methods: {
    ...mapActions(['genCan','getUserRoles','updateUserRole','createUserRole','deleteUserRole','getUserRoutegroups']),

    addRouteGroup(){
      if(this.selected_routegroup !== null){
        this.editedItem.permission[this.selected_routegroup.id] = new Array();
        
        
        // Force Re-render route-groups component 
        this.editedItem = Object.assign({}, this.allUserRoles[this.editedIndex]);
        // this.selected_routegroup = null;

        this.$nextTick(() => {
          this.selected_routegroup = null;
        })
      }
    },

    deleteRouteGroup(id){
      var deleteRG = confirm("Are you sure to delete this route group!");
      if (deleteRG == true) {
        delete this.editedItem.permission[id];

        // Force Re-render route-groups component 
        this.editedItem = Object.assign({}, this.allUserRoles[this.editedIndex]);
      }
    },

    addItem(){
      // this.$refs.form.resetValidation();
      this.editedIndex = -1
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dialog = true;
    },
    
    editItem (item) {
      this.editedIndex = this.allUserRoles.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true;
    },

    deleteItem (item) {
        confirm('Are you sure you want to delete this role?') && this.deleteUserRole(item);
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation() // prevent validate error show again when click add
      }, 300)
    },

    save () {
      if(this.form_valid){
        if (this.editedIndex > -1) {
          this.updateUserRole(this.editedItem);
        } else {
          this.createUserRole(this.editedItem);
        }
        this.close()
      }
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  created() {
    this.genCan();
    this.getUserRoles();
    this.getUserRoutegroups();
  }
};
</script>
<style scoped>
.right-panel {
  border-left : 1px solid #e0e0e0;
  min-height: 400px;
}
.permission-table thead tr{
 background: #e0e0e0;
 font-weight: bold;
}
.permission-table tbody td{
  /* padding-top: 5px; */
  padding-bottom: 5px;
  vertical-align: top;
}

</style>